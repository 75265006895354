import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const CampaignSettings = ({ formData, updateFormData, senders, setIsFormValid }) => {
  const [destinationUrl, setDestinationUrl] = useState(() => formData.destinationUrl || '');
  const [rebrandlyData, setRebrandlyData] = useState(() => {
    if (formData.shortUrl) {
      return {
        shortUrl: formData.shortUrl,
        id: formData.rebrandlyId,
        slashtag: formData.slashtag,
        destination: formData.destinationUrl,
        createdAt: formData.linkCreatedAt
      };
    }
    return null;
  });
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [isDeletingLink, setIsDeletingLink] = useState(false);
  const [linkError, setLinkError] = useState('');
  const [showCopied, setShowCopied] = useState(false);
  const [urlError, setUrlError] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(false);

  const handleInputChange = (field, value) => {
    updateFormData({ [field]: value });
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setDestinationUrl(url);
    
    if (url) {
      const { isValid, error } = validateUrl(url);
      setIsValidUrl(isValid);
      setUrlError(error);
    } else {
      setIsValidUrl(false);
      setUrlError('');
    }
  };

  useEffect(() => {
    const isValid = !!(
      formData.name?.trim() && 
      formData.sender &&
      rebrandlyData
    );
    setIsFormValid(isValid);
  }, [formData.name, formData.sender, rebrandlyData, setIsFormValid]);

  // Функция для валидации URL
  const validateUrl = (url) => {
    try {
      // Убираем пробелы
      const trimmedUrl = url.trim();
      if (trimmedUrl !== url) {
        return { isValid: false, error: 'URL should not contain leading or trailing spaces' };
      }
      if (url.includes(' ')) {
        return { isValid: false, error: 'URL should not contain spaces' };
      }
      
      // Проверяем что это валидный URL
      const urlObject = new URL(url);
      // Проверяем что протокол http или https
      if (!['http:', 'https:'].includes(urlObject.protocol)) {
        return { isValid: false, error: 'URL must start with http:// or https://' };
      }
      
      return { isValid: true, error: '' };
    } catch (e) {
      return { isValid: false, error: 'Please enter a valid URL' };
    }
  };

  const createRebrandlyLink = async () => {
    if (!destinationUrl) return;

    if (!formData.name?.trim()) {
      setLinkError('Please enter a campaign name first');
      return;
    }

    if (!destinationUrl || !isValidUrl) {
      setLinkError('Please enter a valid destination URL');
      return;
    }
    
    setIsCreatingLink(true);
    setLinkError('');

    try {
      const response = await fetch('/api/v1/rebrandly/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          destination_url: destinationUrl,
          title: formData.name
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to create link');
      }

      setRebrandlyData(data);
      updateFormData({ 
        shortUrl: data.shortUrl,
        rebrandlyId: data.id,
        slashtag: data.slashtag,
        fullShortUrl: `https://${data.shortUrl}`,
        destinationUrl: destinationUrl,
        linkCreatedAt: data.createdAt
      });
    } catch (error) {
      setLinkError(error.message);
    } finally {
      setIsCreatingLink(false);
    }
  };

  const deleteAndCreateNewLink = async () => {
    if (!rebrandlyData?.id) return;
    
    setIsDeletingLink(true);
    setLinkError('');

    try {
      const response = await fetch(`/api/v1/rebrandly/${rebrandlyData.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete link');
      }

      // Очищаем данные после успешного удаления
      setRebrandlyData(null);
      setDestinationUrl('');
      updateFormData({ 
        shortUrl: null,
        rebrandlyId: null,
        slashtag: null,
        fullShortUrl: null,
        destinationUrl: null,
        linkCreatedAt: null
      });

      setDeleteSuccessMessage(true);
      setTimeout(() => setDeleteSuccessMessage(false), 5000);
    } catch (error) {
      setLinkError(error.message);
    } finally {
      setIsDeletingLink(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`https://${rebrandlyData.shortUrl}`);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const senderOptions = senders.map(sender => ({
    value: sender.value,
    label: sender.label
  }));

  return (
    <div className="space-y-6">
      {/* Campaign Name */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Campaign Name *
        </label>
        <input
          id="name"
          type="text"
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Enter campaign name"
        />
      </div>

      {/* Sender Selection */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sender">
          Sender *
        </label>
        <Select
          id="sender"
          value={formData.sender}
          onChange={(option) => handleInputChange('sender', option)}
          options={senderOptions}
          placeholder="Select sender"
          isSearchable
          className="text-gray-700"
        />
      </div>

      {/* Rebrandly URL Creation */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Destination URL for Rebrandly *
        </label>
        <div className="flex space-x-2">
          <input
            type="url"
            value={destinationUrl}
            onChange={handleUrlChange}
            className={`flex-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              urlError ? 'border-red-500' : ''
            }`}
            placeholder="Enter destination URL (e.g., https://example.com)"
            disabled={!!rebrandlyData}
          />
          <button
            onClick={createRebrandlyLink}
            disabled={!destinationUrl || !isValidUrl || isCreatingLink || !!rebrandlyData}
            className={`px-4 py-2 rounded font-semibold text-white focus:outline-none ${
              !destinationUrl || !isValidUrl || isCreatingLink || !!rebrandlyData
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {isCreatingLink ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
                Creating...
              </span>
            ) : 'Create'}
          </button>
        </div>
        {urlError && (
          <p className="mt-1 text-sm text-red-600">{urlError}</p>
        )}
        {linkError && (
          <p className="mt-2 text-sm text-red-600">{linkError}</p>
        )}
        {rebrandlyData && (
          <div className="mt-4">
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Short URL:</p>
                  <p className="text-base font-medium text-gray-900">
                    https://{rebrandlyData.shortUrl}
                  </p>
                </div>
                <button
                  onClick={copyToClipboard}
                  className="p-2 text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded-lg transition-colors"
                  title="Copy to clipboard"
                >
                  {showCopied ? (
                    <span className="text-green-600 flex items-center">
                      <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                      </svg>
                      Copied!
                    </span>
                  ) : (
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"/>
                    </svg>
                  )}
                </button>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                <p>Destination: {rebrandlyData.destination || destinationUrl}</p>
                <p>Created: {new Date(rebrandlyData.createdAt).toLocaleString()}</p>
              </div>
            </div>
            <button
              onClick={deleteAndCreateNewLink}
              disabled={isDeletingLink}
              className={`mt-2 text-sm text-red-600 hover:text-red-800 flex items-center ${
                isDeletingLink ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isDeletingLink ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                  Deleting...
                </>
              ) : (
                'Clear and create new link'
              )}
            </button>
          </div>
        )}
        {deleteSuccessMessage && (
          <div className="mt-2 p-3 bg-green-50 border border-green-200 rounded-lg">
            <div className="flex items-center text-green-600">
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
              </svg>
              <span>Link successfully deleted. You can now create a new one.</span>
            </div>
          </div>
        )}
      </div>

      {/* Send Time Options */}
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Send Time
        </label>
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="sendNow"
              value="now"
              checked={formData.sendTime === 'now'}
              onChange={(e) => handleInputChange('sendTime', e.target.value)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label htmlFor="sendNow" className="ml-2 block text-gray-700">
              Send Now
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="radio"
              id="sendLater"
              value="later"
              checked={formData.sendTime === 'later'}
              onChange={(e) => handleInputChange('sendTime', e.target.value)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
            />
            <label htmlFor="sendLater" className="ml-2 block text-gray-700">
              Schedule for Later
            </label>
          </div>

          {formData.sendTime === 'later' && (
            <div className="ml-6">
              <input
                type="datetime-local"
                value={formData.scheduledTime}
                onChange={(e) => handleInputChange('scheduledTime', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                min={new Date().toISOString().slice(0, 16)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignSettings;