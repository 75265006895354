import React, { useState } from 'react';
import CampaignCard from './CampaignCard';

const CampaignList = ({ initialCampaigns, initialStats }) => {
  const [activeTab, setActiveTab] = useState('all');
  const campaigns = initialCampaigns || [];
  const stats = initialStats || {
    total: 0,
    delivered: 0,
    pending: 0,
    failed: 0
  };

  const tabCounts = {
    all: campaigns.length,
    inProgress: campaigns.filter(c => c.stats.pending > 0).length,
    completed: campaigns.filter(c => c.stats.total === c.stats.sent).length,
    scheduled: campaigns.filter(c => c.send_time === 'later').length
  };

  const getFilteredCampaigns = () => {
    switch (activeTab) {
      case 'inProgress':
        return campaigns.filter(c => c.stats.pending > 0);
      case 'completed':
        return campaigns.filter(c => c.stats.total === c.stats.sent);
      case 'scheduled':
        return campaigns.filter(c => c.send_time === 'later');
      default:
        return campaigns;
    }
  };

  return (
    <div className="min-h-screen bg-slate-50/50">
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-semibold text-slate-900">Campaigns</h1>
            <p className="mt-1 text-sm text-slate-600">Manage and monitor message delivery</p>
          </div>

          <a href="/campaigns/new" 
             className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors shadow-sm">
            <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            New Campaign
          </a>
        </div>

        {/* Tabs and Stats */}
        <div className="bg-white rounded-xl border border-slate-200/70 shadow-sm mb-6">
          <div className="border-b border-slate-200">
            <nav className="flex">
              <button
                onClick={() => setActiveTab('all')}
                className={`flex-1 py-4 px-4 text-sm font-medium text-center ${
                  activeTab === 'all' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-slate-500'
                }`}
              >
                All Campaigns <span className="ml-2 text-xs rounded-full bg-slate-100 px-2 py-0.5">{tabCounts.all}</span>
              </button>
              <button
                onClick={() => setActiveTab('inProgress')}
                className={`flex-1 py-4 px-4 text-sm font-medium text-center ${
                  activeTab === 'inProgress' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-slate-500'
                }`}
              >
                In Progress <span className="ml-2 text-xs rounded-full bg-slate-100 px-2 py-0.5">{tabCounts.inProgress}</span>
              </button>
              <button
                onClick={() => setActiveTab('completed')}
                className={`flex-1 py-4 px-4 text-sm font-medium text-center ${
                  activeTab === 'completed' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-slate-500'
                }`}
              >
                Completed <span className="ml-2 text-xs rounded-full bg-slate-100 px-2 py-0.5">{tabCounts.completed}</span>
              </button>
              <button
                onClick={() => setActiveTab('scheduled')}
                className={`flex-1 py-4 px-4 text-sm font-medium text-center ${
                  activeTab === 'scheduled' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-slate-500'
                }`}
              >
                Scheduled <span className="ml-2 text-xs rounded-full bg-slate-100 px-2 py-0.5">{tabCounts.scheduled}</span>
              </button>
            </nav>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 divide-x divide-slate-200/60">
            <div className="p-4 text-center">
              <div className="text-2xl font-semibold text-slate-700">{stats.total}</div>
              <div className="text-sm text-slate-600">Total Messages</div>
            </div>
            <div className="p-4 text-center">
              <div className="text-2xl font-semibold text-emerald-600">{stats.delivered}</div>
              <div className="text-sm text-slate-600">Delivered</div>
            </div>
            <div className="p-4 text-center">
              <div className="text-2xl font-semibold text-amber-600">{stats.pending}</div>
              <div className="text-sm text-slate-600">Pending</div>
            </div>
            <div className="p-4 text-center">
              <div className="text-2xl font-semibold text-rose-600">{stats.failed}</div>
              <div className="text-sm text-slate-600">Failed</div>
            </div>
          </div>
        </div>

        {/* Campaign Cards */}
        <div className="space-y-4">
          {getFilteredCampaigns().length === 0 ? (
            <div className="text-center py-12 bg-white rounded-lg shadow-sm">
              <p className="text-slate-500">No campaigns found</p>
            </div>
          ) : (
            getFilteredCampaigns().map(campaign => (
              <CampaignCard key={campaign.id} campaign={campaign} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignList;